export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  CreateCheckoutFromCart: 'specs.stores.CreateCheckoutFromCart',
  UseNewCartAndCheckoutMutationForQa: 'specs.stores.UseNewCartAndCheckoutMutationForQa',
  CartApiFastFlowWithCreateCheckout: 'specs.stores.CartApiFastFlowWithCreateCheckout',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  isPreOrderEnabled: 'specs.stores.PreOrderClient',
  ShowAutomaticDiscountsInCartClient: 'specs.stores.ShowAutomaticDiscountsInCartClient',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  BiReport113EventTo130Source: 'specs.stores.BiReport113EventTo130Source',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  SendUpdateBuyerNoteBiInCart: 'specs.stores.SendUpdateBuyerNoteBiInCart',
  PickupPointsBmPhase1: 'specs.stores.PickupPointsBmPhase1',
  DisplayAdditionalFees: 'specs.stores.DisplayAdditionalFees',
  ShouldFetchAdditionalFees: 'specs.stores.ShouldFetchAdditionalFees',
  VeloValidationOnCheckout: 'specs.stores.veloValidationOnCheckout',
} as const;
